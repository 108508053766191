import { Fragment, useEffect, useState, useCallback, useRef } from 'react';
import { useRouter, usePathname } from 'next/navigation';
import { Button, IconButton } from '@components/shared/material';
import { useMatchMedia } from '@/lib/matchMedia';

export default function Pagination(params: {
  onPageChange: (newPage: number) => void;
  currentPage: number;
  totalPageCount: number;
  loadingFromParent: boolean;
  scrollRef?: React.RefObject<HTMLDivElement> | null;
}) {
  const pathname = usePathname();
  const router = useRouter();
  const onPageChange = params.onPageChange;
  const currentPage = params.currentPage;
  const totalPageCount = params.totalPageCount;
  const [activePage, setActivePage] = useState(currentPage);
  const loadingFromParent = params.loadingFromParent;
  const loadingRef = useRef(loadingFromParent);
  const scrollRef = params.scrollRef;

  const isDesktopResolution = useMatchMedia('(min-width:600px)', true);

  useEffect(() => {
    setActivePage(currentPage);
  }, [currentPage]);

  useEffect(() => {
    loadingRef.current = loadingFromParent; // Ref used to create stable mutable reference so that useCallback is not recreated unnecessarily
  }, [loadingFromParent]);

  const handlePageChange = useCallback(
    (page: number) => {
      if (!loadingRef.current) {
        // if parent currently loading  / making api call
        setActivePage(page);
        onPageChange(page);
        setTimeout(() => {
          if (scrollRef && scrollRef.current) {
            scrollRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'start',
            });
          }
        }, 0);
        router.replace(page === 1 ? pathname : `${pathname}?page=${page}`, {
          scroll: false,
        });
      }
    },
    [onPageChange, pathname, router, scrollRef]
  );

  const getPageList = () => {
    let pages: any[] = [];

    if (totalPageCount <= 6) {
      // Not enough pages for truncation, just show them all
      pages = Array.from({ length: totalPageCount }, (_, i) => i + 1);
    } else {
      // Adjust the range of visible pages based on the resolution
      let range = isDesktopResolution ? 1 : 0; // Less range for mobile
      let extendedRange = isDesktopResolution ? 2 : 1; // More pages for desktop, fewer for mobile
      let startPage = Math.max(2, activePage - range);
      let endPage = Math.min(totalPageCount - 1, activePage + range);

      if (activePage <= 3 + extendedRange) {
        startPage = 2;
        endPage = 2 + (isDesktopResolution ? 3 : 1); // Adjust for mobile/desktop
      } else if (activePage > totalPageCount - (3 + extendedRange)) {
        startPage = totalPageCount - (2 + (isDesktopResolution ? 3 : 2)); // Adjust for mobile/desktop
        endPage = totalPageCount - 1;
      }

      pages = [
        1,
        ...Array.from(
          { length: endPage - startPage + 1 },
          (_, i) => i + startPage
        ),
        totalPageCount,
      ];

      // Insert ellipses for gaps to other pages, adjusting as necessary
      if (activePage > 3 + extendedRange) {
        pages.splice(1, 0, '...');
      }
      if (activePage <= totalPageCount - (3 + extendedRange)) {
        pages.splice(pages.length - 1, 0, '...');
      }
    }

    return pages;
  };

  return (
    <div className='flex items-center gap-4 overflow-hidden'>
      <Button
        variant='text'
        className='!rounded-xl text-sm hover:bg-gray-100'
        onClick={() => handlePageChange(activePage - 1)}
        disabled={activePage === 1 || loadingFromParent}
      >
        Previous
      </Button>
      <div className='flex items-center gap-2'>
        {getPageList().length &&
          getPageList().map((page, index) => (
            <Fragment key={index}>
              {page === '...' ? (
                <span>...</span>
              ) : (
                <IconButton
                  variant={activePage === page ? 'filled' : 'text'}
                  className={activePage === page ? 'bg-black' : ''}
                  onClick={() => handlePageChange(page)}
                  disabled={loadingFromParent}
                >
                  {page}
                </IconButton>
              )}
            </Fragment>
          ))}
      </div>
      <Button
        variant='text'
        className='!rounded-xl text-sm hover:bg-gray-100'
        onClick={() => handlePageChange(activePage + 1)}
        disabled={activePage === totalPageCount || loadingFromParent}
      >
        Next
      </Button>
    </div>
  );
}
