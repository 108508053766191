'use client';
import { useEffect, useState } from 'react';
import {
  format,
  formatDistanceToNow,
  differenceInCalendarDays,
  parseISO,
} from 'date-fns';

export default function PostDate({ date }: { date: Date }) {
  const [displayDate, setDisplayDate] = useState<string>('');

  useEffect(() => {
    const updateDate = () => {
      const parsedDate = typeof date === 'string' ? parseISO(date) : date;
      const now = new Date();
      const diffInDays = differenceInCalendarDays(now, parsedDate);

      if (diffInDays > 1) {
        const formattedDate = format(parsedDate, 'MMM d, yyyy');
        setDisplayDate(formattedDate);
      } else {
        const formattedDate = formatDistanceToNow(parsedDate, {
          addSuffix: true,
        });
        setDisplayDate(formattedDate);
      }

      return diffInDays;
    };

    const diffInDays = updateDate(); // Update immediately on component mount, and get the relative time diff
    let updateDateInterval: NodeJS.Timer | null = null;

    // Set interval only when date is less than a day old
    if (diffInDays <= 1) {
      updateDateInterval = setInterval(() => {
        updateDate(); // Then update every minute
      }, 60000); // 60000 ms is 1 minute
    }

    return () => {
      if (updateDateInterval) {
        clearInterval(updateDateInterval); // Clear interval on component unmount
      }
    };
  }, [date]);

  return <span>{displayDate}</span>;
}
