'use client';
import { Card, CardBody, CardHeader } from '@/components/shared/material';

export default function PaginatedPostsLoader() {
  const articles = [{}, {}, {}, {}, {}, {}, {}, {}, {}];
  return (
    <div className='container-padding container mx-auto grid gap-8 md:grid-cols-2 lg:grid-cols-3'>
      {articles &&
        articles.map((_article, index) => (
          <div className='group flex' key={index}>
            <Card className='mt-6 flex w-full transform flex-col border-gray-300 bg-white/80 shadow-[0_0_50px_10px_rgba(0,0,0,0.1)] duration-300 group-hover:translate-y-[-5px]'>
              <CardHeader className='relative h-56 transform duration-300 group-hover:translate-y-[-10px]'>
                <div className='h-[450px] w-full animate-pulse bg-gray-300'></div>
              </CardHeader>
              <CardBody className='flex flex-grow flex-col gap-4'>
                <div className='animate-pulse rounded bg-gray-300 py-3' />
                <div className='mt-auto flex items-center justify-between'>
                  <div className='w-[150px] animate-pulse rounded bg-gray-300 py-1' />
                  <div className='w-[80px] animate-pulse rounded bg-gray-300 py-1' />
                </div>
              </CardBody>
            </Card>
          </div>
        ))}
    </div>
  );
}
